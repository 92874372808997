import { useAuth } from 'shared/hooks/use-auth';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useIsSmallScreen } from 'shared/hooks/use-is-small-screen';

// TODO: Refactor shared/utils/routing/get-return-url.ts so that it can be used
// here instead, alongside auth guards.

const Redirect = () => {
  const auth = useAuth();
  const router = useRouter();
  const isSmallScreen = useIsSmallScreen();
  const { user, isAuthenticated } = auth;

  const handleClientRedirect = () => {
    if (user?.clientStage === 'fully_onboarded') {
      if (isSmallScreen && user.personalPortfolioId != null) {
        router.push(`/savvy-portfolio/${user.personalPortfolioId}`);
      } else {
        router.push(`/portfolio`);
      }
    } else {
      router.push('/dashboard');
    }
  };

  const handleNonClientRedirect = () => {
    router.push('/advisor/dashboard/clients');
  };

  useEffect(() => {
    if (!isAuthenticated || !user) {
      router.push('/login');
    } else if (user.role === 'CLIENT') {
      handleClientRedirect();
    } else if (user.role === 'NON_ADVISORY_USER') {
      router.push('/portfolio');
    } else {
      handleNonClientRedirect();
    }
  }, [auth, router]);

  return null;
};

export default Redirect;
